<template>
    <standard-page v-bind:title="short_name" sub_title="Add DID Controller">
        <template v-slot:breadcrumb>
            <span><router-link :to="{ name: 'identities-home'}">Identifier</router-link></span>
            <span><router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link></span>
            <span><router-link :to="{ name: 'identities-catalogs-dids'}" v-if="catalogName">CATALOG: {{catalogName | truncate(20, '.....')}}</router-link></span>
            <span v-if="short_name">DID: {{short_name | truncate(20, '.....')}}</span>
        </template>
        <template v-slot:sub-title>
            <p class="mg-b-10">
                <a href="javascript:void(0);" v-clipboard:copy="did" v-clipboard:success="copied">{{did | did}}</a>
            </p>
        </template>
        <template v-slot:content>
            <form @submit.prevent="handleSubmit">
                <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
                    <div class="row row-xs">
                        <div class="col-md-10">
                            <multiselect :group-select="true" :multiple="true" :options="filtered_controllers" class="form-control" label="name" placeholder="Type to search" track-by="entity_id"
                                         v-model="values">
                                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                            </multiselect>
                            <div class="invalid-feedback" v-if="submitted && errors.has('name')">{{ errors.first('name') }}</div>
                        </div>
                        <div class="col-md mg-t-10 mg-md-t-0">
                            <button class="btn rounded btn-az-primary btn-block" type="submit">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </standard-page>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import EventBus from "@/event-bus";

    export default {
        name: "AddDidController",
        components: {Multiselect},
        props: ['catalogid', 'did'],
        data() {
            return {
                submitted: false,
                catalogName: null,
                short_name: null,
                values: [],
                filtered_controllers: [],
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await Promise.all([
                    this.getOrgs(),
                    this.getCatalog(),
                    this.getDid()
                ]);
            },
            async getOrgs() {
                try {
                    this.filtered_controllers = [];
                    let {data} = await this.$accounts.get('/accounts/orgs');
                    this.orgs = data.orgs;
                    let mapped_orgs = this.orgs.map(o => o.pub_key);
                    let filtered_pub_keys = _.difference(mapped_orgs, this.controllers);
                    if (filtered_pub_keys.length > 0) {
                        let {data} = await this.$accounts.post('/accounts/org/by_public_keys', {
                            pub_keys: filtered_pub_keys
                        });
                        this.filtered_controllers = data.orgs;
                    }
                } catch (e) {

                }
            },
            async getCatalog() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}`);
                    this.catalogName = reply.data ? reply.data.name : null;
                } catch (e) {
                    this.$toastr.e("Error getting catalog name", "Error");
                }
            },
            async getDid() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}/dids/${this.did}`);
                    this.short_name = reply.data ? reply.data.short_name : null;
                } catch (e) {
                    this.$toastr.e("Error getting DID short_name", "Error");
                }
            },
            copied() {
                this.$toastr.i("Copied to clipboard", "Copied");
            },
            handleSubmit(e) {
                this.submitted = true;
                this.$validator.validate()
                        .then(valid => {
                            if (valid) {
                                this.addController();
                            }
                        });
            },
            async addController() {
                if (this.values.length > 0) {
                    try {
                        EventBus.$emit('openLoader');
                        await this.$identity.patch(`/identity/dids/${this.did}/controllers`, {
                            add: this.values.map(c => c.pub_key)
                        });
                        this.$toastr.s("Controller added successfully", "Success");
                        this.values = [];
                        await this.$router.push({name: 'viewdid'});
                    } catch (e) {
                        this.$toastr.e("Controller add failed", "Error");
                    } finally {
                        EventBus.$emit('closeLoader');
                    }
                } else {
                    this.$toastr.e("Choose at least one controller", "Error");
                }
            },
        }
    }
</script>

<style scoped>

</style>